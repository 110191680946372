import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Labor Day Partner WOD`}</p>
    <p>{`50 Calorie Ski Erg`}</p>
    <p>{`240m Run to Turf`}</p>
    <p>{`280ft Sled Push (sled +90/45`}{`#`}{`)`}</p>
    <p>{`240m Run to Front`}</p>
    <p>{`100 Wall Balls (20/14)`}</p>
    <p>{`100 Situps`}</p>
    <p>{`50 Calorie Row`}</p>
    <p>{`for time.`}</p>
    <p>{`*`}{`We will have our free Labor Day wod at 9:00 & 10:30am. All other
classes today are cancelled.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      